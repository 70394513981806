import React from "react";

import { cn } from "~/utilities/cn";

interface IconChevronDownProps {
  className?: string; // Optional className to pass Tailwind CSS styles
  open?: boolean; // For rotation state (optional, default is `false`)
}

const IconChevronDown: React.FC<IconChevronDownProps> = ({
  className,
  open = false,
}) => {
  return (
    <svg
      className={cn(
        "h-3 w-3 text-color transition-all duration-300",
        open ? "rotate-180" : "",
        className // Applying any additional className passed via props
      )}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 8"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 1l5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
      />
    </svg>
  );
};

export default IconChevronDown;
