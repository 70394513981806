import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  ListboxSelectedOption,
  Transition,
} from "@headlessui/react";
import { useCallback, useEffect, useState } from "react";
import { useRemixFormContext } from "remix-hook-form";

import IconChevronDown from "../Icons/IconChevronDown";
import Typo, { TypoProps } from "../Typo";

import { cn } from "~/utilities/cn";
import { OPTION_ANY } from "~/utilities/constants/common";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export type SelectOptions = {
  id: string;
  bedRoomCount?: string;
  isStudio?: string;
  name: string;
};

export type TSelectVariants = "standard" | "fill";

export interface SingleSelectProps {
  title?: string;
  name: string;
  placeholder?: string;
  data: SelectOptions[];
  defaultValue?: SelectOptions;
  error?: string;
  variant?: TSelectVariants;
  titleProps?: TypoProps;
  dropdownClass?: string;
  containerClass?: string;
  listboxButtonContainerClass?: string;
  listboxButtonClass?: string;
  placeholderClass?: string;
  textSelectedClass?: string;
  placeholderContainerClass?: string;
  placeholderProps?: Omit<TypoProps, "tag">;
  hasAnyOption?: boolean;
  showOptionSelectModal?: boolean;
  dropdownIconClassName?: string;
  onShowOptionSelectModal?: () => void;
  onChange?: (value: string) => void;
}

const _variantStyle: Record<
  TSelectVariants,
  { button: string; container: string; textSelected: string }
> = {
  standard: {
    container:
      "flex items-center justify-between gap-x-2 border-b border-color pb-3.5 lg:pb-2",
    button:
      "w-full cursor-pointer gap-x-2 bg-transparent pb-1.5 text-sub-title-16 text-color outline-none",
    textSelected: "line-clamp-1 text-start",
  },
  fill: {
    container: "flex items-center justify-between",
    button:
      "w-full bg-white border border-darkGrey25 rounded-3xl px-[18px] py-3  ",
    textSelected: "line-clamp-1 text-start text-sub-title-14",
  },
};

export default function SingleSelect({
  data,
  name,
  defaultValue,
  error,
  title = "",
  placeholder,
  variant = "standard",
  titleProps,
  dropdownClass = "",
  containerClass = "",
  listboxButtonContainerClass = "",
  listboxButtonClass = "",
  placeholderClass = "",
  textSelectedClass = "",
  placeholderContainerClass = "",
  placeholderProps,
  hasAnyOption = true,
  onShowOptionSelectModal,
  dropdownIconClassName = "",
  showOptionSelectModal = false,

  onChange,
}: SingleSelectProps) {
  const [selected, setSelected] = useState<SelectOptions | null>(
    defaultValue ? defaultValue : null
  );

  const options = hasAnyOption ? [OPTION_ANY, ...data] : data;

  const { setValue } = useRemixFormContext();

  useEffect(() => {
    setValue(name, selected && selected.id && selected?.id.toString());
    if (typeof onChange === "function") {
      onChange(selected?.id || "");
    }
  }, [name, selected, setValue]);

  const handleChange = useCallback(
    (data: SelectOptions) => {
      setSelected(data);
      setValue(name, data?.id?.toString());
    },
    [name, setValue]
  );

  // useEffect(() => {
  //   const found = options?.find((option) => option?.id === defaultValue?.id);
  //   if (found) setSelected(found);
  // }, [defaultValue, options]);

  return (
    <div className={cn("overflow-hidden", containerClass)}>
      {title && (
        <Typo
          tag={ETypoTag.P}
          color={ETypoColor.TEXT}
          variant={ETypoVariant.SUB_TITLE_20}
          className={cn("mb-4.5 font-bold lg:mb-2 lg:text-sub-title-16")}
          {...titleProps}
        >
          {title}
        </Typo>
      )}
      <div className="relative w-full">
        <Listbox value={selected} onChange={handleChange}>
          {({ open }) => {
            if (open === true) {
              const htmlElement = document.documentElement;
              htmlElement.style.overflow = "unset";
              htmlElement.style.paddingRight = "0px";
              const bodyElement = document.querySelector("body");
              if (bodyElement) {
                bodyElement.style.overflow = "unset";
                bodyElement.style.paddingRight = "0px";
              }
            }
            return (
              <>
                <ListboxButton
                  className={cn(
                    _variantStyle[variant].button,
                    listboxButtonClass
                  )}
                >
                  <div
                    className={cn(
                      _variantStyle[variant].container,
                      listboxButtonContainerClass
                    )}
                  >
                    <ListboxSelectedOption
                      options={
                        <>
                          <p
                            className={cn(
                              _variantStyle[variant].textSelected,
                              textSelectedClass
                            )}
                          >
                            {selected?.name}
                          </p>
                          <div
                            className={cn(
                              "group pointer-events-none fill-white60",
                              dropdownIconClassName
                            )}
                            aria-hidden="true"
                          >
                            {/* <Icon
                              TypeIcon={EIcon.ChevronDownIcon}
                              height={20}
                              width={20}
                              stroke={dropdownIconClassName}
                              className={cn(
                                "fill-white60 transition-all duration-300",
                                open ? "rotate-180" : ""
                              )}
                            /> */}
                            <IconChevronDown open={open} />
                          </div>
                        </>
                      }
                      placeholder={
                        <div
                          className={cn(
                            "flex w-full items-center justify-between",
                            placeholderContainerClass
                          )}
                        >
                          <Typo
                            tag={ETypoTag.SPAN}
                            color={ETypoColor.FILTER_BAR}
                            variant={
                              variant === "standard"
                                ? ETypoVariant.BODY_TITLE_16
                                : ETypoVariant.BODY_TITLE_14
                            }
                            className={cn(
                              "inline-block leading-[150%] opacity-25",
                              placeholderClass
                            )}
                            {...placeholderProps}
                          >
                            {placeholder}
                          </Typo>
                          {/* <Icon
                            TypeIcon={EIcon.ChevronDownIcon}
                            height={20}
                            width={20}
                            className={cn(
                              "m-2 h-3 w-3 text-color transition-all duration-300",
                              open ? "rotate-180" : ""
                            )}
                          /> */}
                          <IconChevronDown
                            open={open}
                            className={dropdownIconClassName}
                          />
                        </div>
                      }
                    ></ListboxSelectedOption>
                  </div>
                </ListboxButton>
                <Transition
                  show={open}
                  enter="transition-opacity duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <ListboxOptions
                    anchor="bottom start"
                    className={cn(
                      "scroll z-100 w-[calc(var(--button-width)+80px)] rounded-xl border border-white5 bg-white pt-1.5 shadow-dropdownSelect focus:outline-none lg:w-[var(--button-width)]",
                      dropdownClass
                    )}
                  >
                    {options &&
                      options.map((item) => (
                        <ListboxOption
                          key={item.id}
                          value={item}
                          className="group flex cursor-pointer select-none items-center p-3 data-[focus]:bg-cornflowerBlue lg:p-2"
                        >
                          <Typo
                            tag={ETypoTag.P}
                            variant={ETypoVariant.SUB_TITLE_16}
                            color={ETypoColor.FILTER_BAR}
                            className="line-clamp-1"
                          >
                            {item.name}
                          </Typo>
                        </ListboxOption>
                      ))}
                  </ListboxOptions>
                </Transition>
              </>
            );
          }}
        </Listbox>
        {showOptionSelectModal && (
          <button
            className="absolute left-0 top-0 h-full w-full cursor-pointer bg-transparent"
            onClick={() => {
              if (typeof onShowOptionSelectModal === "function") {
                onShowOptionSelectModal();
              }
            }}
          />
        )}
      </div>
      {error && (
        <Typo
          tag={ETypoTag.P}
          variant={ETypoVariant.BODY_TITLE_14}
          color={ETypoColor.ERROR}
        >
          {error}
        </Typo>
      )}
    </div>
  );
}
